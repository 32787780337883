html {
  overscroll-behavior: none;
}

body {
  overscroll-behavior: none;
}

.App {
  text-align: center;
  overflow: hidden;
  overscroll-behavior: none;
  height: 100vh;
  width: 100vw;
  height: 100dvh;
}